import './Home.scss';
import {forwardRef} from "react";
import {useNavigate} from "react-router-dom";

function Latest(props, ref) {
  const navigate = useNavigate();
  const navTo = (id) => {
    if(id === 'makita') {
      window.open('/makita-ecommerce.pdf', '_blank');
      return
    }
    navigate(`/projects/${id}`)
  }

  return (
      <section id="latest" ref={ref}>
        <h2>Latest work</h2>
        <div>
          <div onClick={() => navTo('makita')}>
            <img src={require('../projects/asset/cover4.png')} alt={'4'}/>
            <p>Makita E-Commerce</p>
            <span>
              <a href={'https://www.figma.com/design/id7Eq4YIbgyyyAN4NrhGUW/MAKITA---Online-Ecommerce-website?node-id=2161-14386&t=5MuIDeoWOoLFF4hw-1'} target={'_blank'} rel={'noopener noreferrer nofollow'}>Figma</a>
            </span>
          </div>
          <div onClick={() => navTo('fibonacci')}>
            <img src={require('../projects/asset/cover1_new.png')} alt={'1'}/>
            <p>Fibonacci</p>
          </div>
          <div onClick={() => navTo('techdesk')}>
            <img src={require('../projects/asset/cover2_new.png')} alt={'2'}/>
            <p>TechDesk</p>
          </div>
          <div onClick={() => navTo('journeyhub')}>
            <div>
              <img src={require('../projects/asset/cover3_new.png')} alt={'3'}/>
            </div>
            <p>JourneyHub</p>
          </div>

          {/*<div onClick={()=>navTo('5')} >*/}
          {/*  <img src={'/work5.png'} alt={'5'} />*/}
          {/*  <p>Work title 5</p>*/}
          {/*</div>*/}
          {/*<div onClick={()=>navTo('6')} >*/}
          {/*  <img src={'/work6.png'} alt={'6'} />*/}
          {/*  <p>Work title 6</p>*/}
          {/*</div>*/}
        </div>
      </section>
  );
}

export default forwardRef(Latest);