import '../Project.scss';

function JourneyHub() {
    return (
      <div className="project">
        <header>
          <h1>Overview</h1>
          <p><span>JourneyHub</span> is a Vacation Search Engine Including recommendations and tips to enhance your
            trip.
          </p>
          <br/>
          <p><span>platform</span> mac’s, tablets, iPhones <span>my role</span> ui/ux</p>
        </header>
        <section className='about'>
          <div>
            <h3 className={'title'}>Process</h3>
            <p>In creating vacation website,
              I embarked on a journey of meticulous research, wireframing, and design iteration. I focused on crafting a visually appealing interface and seamless user experience, incorporating feedback and testing to refine the final product.
              The result? A captivating website that invites users to explore and discover their dream destinations with ease.
            </p>
          </div>
          <div>
            <img src={require('../asset/jh1.png')} alt={'jh1'}/>
          </div>
        </section>
        <section id={'design-system'}>
          <div className={'header'}>
            <h3>Design system</h3>
            <img src={require('../asset/palletta2.png')} alt={'plt2'}/>
          </div>
          <div className={'main'}>
            <img src={require('../asset/dsjh1.png')} alt={'dsjh1'} className={'dsjh1'}/>
            <img src={require('../asset/dsjh2.png')} alt={'dsjh2'} className={'dsjh2'}/>
          </div>
        </section>
        <section id={'jhtextsectoin'}>
          <p>
            Sleek, modern, and user-friendly, the design of our vacation website seamlessly blends captivating visuals
            with intuitive navigation. From vibrant imagery to clean typography, every element is carefully curated to
            inspire wanderlust and guide users on their journey to discovering their perfect getaway.
          </p>
          <img src={require('../asset/jh5.png')} alt={'jh5'}/>
        </section>
        {/*<section className={'design jh'}>*/}
        {/*  <div className={'nowrap'} >*/}
        {/*    <div className={'first'}>*/}
        {/*      <img src={require('../asset/jh2.png')} alt={'jh2'}/>*/}
        {/*    </div>*/}
        {/*    <div className={'second'}>*/}
        {/*      <img src={require('../asset/jh3.png')} alt={'jh3'}/>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className={'third'}>*/}
        {/*    <div><img src={require('../asset/jh4.png')} alt={'jh4'}/></div>*/}
        {/*    <div className={'text'}>*/}
        {/*      <h3 className={'title'}>*/}
        {/*        Design*/}
        {/*      </h3>*/}
        {/*      <p>*/}
        {/*        I chose a youthful design approach while preserving TechDesk's existing framework. This decision appeals*/}
        {/*        to a younger demographic while maintaining alignment with our brand's identity and values. The result is*/}
        {/*        a blend of tradition and innovation that resonates with both current and potential customers.*/}
        {/*      </p>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</section>*/}

        <section className={'fcover'}>
          <div><img src={require('../asset/jh7.png')} alt={'jh7'}/></div>
        </section>
      </div>
    )
}

export default JourneyHub;