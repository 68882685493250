import '../Project.scss';

function Fibon() {
  return (
    <div className="project">
      <header>
        <h1>Overview</h1>
        <p><span>Fibonacci</span> (Home-task) is an experienced global payment service provider,
          regulated by the FCA, dedicated to supporting business growth and delivering
          the right payment solutions to meet your needs.
        </p>
        <br/>
        <p><span>platform</span> mac’s, tablets, iPhones <span>My role</span> product designer</p>
      </header>
      <section className='about'>
        <div>
          <h3 className={'title'}>About the app</h3>
          <p>This app allow the user to make a
            bank transfer from his account to other accounts in three currencies of his choice,
            US Dollar, Euro or GBP.
            This screen is a part of a
            “virtual bank account”
            service we provide our merchants.
          </p>
        </div>
        <div>
          <img src={require('../asset/a1_new.png')} alt={'a1'} />
        </div>
      </section>
      <section id={'design-system'} >
        <div className={'header'}>
        <h3>Design system</h3>
        <img src={require('../asset/palletta1.png')} alt={'pal1'} />
        </div>
        <div className={'main'}>
        <img src={require('../asset/dsl1_new.png')} alt={'dsl1'} className={'dsl1'}/>
        <img src={require('../asset/dsd1_new.png')} alt={'dsd1'} className={'dsl2'} />
        </div>
      </section>
      {/*<section className='design'>*/}
      {/*  <div>*/}
      {/*    <h3 className={'title'}>*/}
      {/*      Design system*/}
      {/*    </h3>*/}
      {/*  </div>*/}
      {/*  <div>*/}
      {/*    <img src={require('../asset/b1.svg').default} alt='b1' />*/}
      {/*    <img className={'c1'} src={require('../asset/c1.png')} alt='c1' />*/}
      {/*    <img className={'c1-mobile'} src={require('../asset/c1-mobile.png')} alt='c1-mobile' />*/}
      {/*  </div>*/}
      {/*</section>*/}
      <section className={'description fibon'}>
        <div className={'text'}>
          <p>With the mobile app, you can take control of your finances with ease. Whether you're making payments, managing invoices, or tracking transactions, our intuitive interface makes it simple and straightforward. Say goodbye to cumbersome processes and hello to efficiency and convenience.</p>
        </div>
        <div className={'image'}>
          <img src={require('../asset/a6_new.png')} alt='d1' />
        </div>
      </section>
    </div>
  );
}

export default Fibon;