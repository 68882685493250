import './Navbar.scss';
import {useEffect, useState} from "react";

function Navbar({onClickOutside}) {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [activeView, setActiveView] = useState('home');

  const onClick = async (arg) => {
    if(showMobileMenu) {
      setShowMobileMenu(false);
      document.body.style.overflow = "unset"
    }
    setActiveView(arg);
    onClickOutside(arg);
  }
  useEffect(() => {
    if(showMobileMenu) {
      document.body.style.overflow = "hidden"
      return
    }
    document.body.style.overflow = "unset"
    return () => {
      document.body.style.overflow = "unset"
    };
  }, [showMobileMenu]);


  return (
    <nav className="Navbar">
      <div onClick={() => onClick('home')}>
        <img src={'/logo.png'} alt={'logo'}/>
      </div>
      <div className={`hamburger ${showMobileMenu ? 'open' : ''}`} onClick={()=>setShowMobileMenu(!showMobileMenu)}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <NavItems onClick={onClick} classname={'desktop'} activeView={activeView}/>
      {
        showMobileMenu &&
        <div className={'mobile-menu'}>
          <NavItems onClick={onClick} classname={'mobile'} activeView={activeView}/>
        </div>
      }
    </nav>
  );
}

const NavItems = ({onClick, classname, activeView}) => (
  <ul className={classname}>
    <li className={activeView === 'home' ? 'active': ''} onClick={()=>onClick('home')}>
      Home
      <span></span>
    </li>
    <li className={activeView === 'latest' ? 'active': ''} onClick={()=>onClick('latest')}>
      Latest works
      <span></span>
    </li>
    <li className={activeView === 'footer' ? 'active': ''} onClick={()=>onClick('footer')}>
      Contact
      <span></span>
    </li>
  </ul>
)
export default Navbar;