import './App.scss';
import Navbar from "./components/nav/Navbar";
import Footer from "./components/footer/Footer";
import Home from "./pages/home/Home";
import {useEffect, useRef} from "react";
import Latest from "./pages/home/Latest";
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Project from "./pages/projects/Project";
function App() {
  const latestRef = useRef(null);
  const footerRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const onClick = (id) => {

    if(id === 'footer') {
      footerRef.current.scrollIntoView({
        behavior: "smooth",
      });
      return
    }
    if(location.pathname !== '/') return navigate(`/`);
    else if (id === 'latest') {
      latestRef.current.scrollIntoView({
        behavior: "smooth",
      });
    } else {
      window.scroll({
        behavior: "smooth",
        top: 0
      });
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [location.pathname]);

  return (
    <div className="App">
      <Navbar onClickOutside={onClick} />
      <div className={'layout'}>
      <Routes>
        <Route path={'/'} index element={
            <Home>
              <Latest ref={latestRef}/>
            </Home>
          }
        />
        <Route path={'/projects/:project'} element={<Project /> }/>
        <Route path='*' element={<Navigate to='/' replace={true} />} />
      </Routes>
      <Footer ref={footerRef} />
      </div>
    </div>
  );
}

export default App;