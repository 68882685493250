import '../Project.scss';

function Techdesk() {
  return (
    <div className="project">
      <header>
        <h1>Overview</h1>
        <p><span>TechDesk</span> is a company The that specializes in technological solutions,
          transforming offices into digital ones.
        </p>
        <br/>
        <p><span>platform</span> mac’s, tablets, iPhones <span>the mission</span> re-design</p>
      </header>
      <section className='about'>
        <div>
          <h3 className={'title'}>Process</h3>
          <p>I've crafted a captivating landing page for TechDesk's professional development courses, focusing on user experience and modern design. By analyzing TechDesk's brand identity, I ensured seamless integration of colors, fonts, and imagery. The layout prioritizes intuitive navigation and clear calls-to-action, while persuasive copy highlights the benefits of our courses. Testimonials and success stories build credibility and confidence in potential participants.
          </p>
        </div>
        <div>
          <img src={require('../asset/a2.svg').default} alt={'a2'} />
        </div>
      </section>
      <section className='design techdesk'>
        <div className={'images'}>
          <img src={require('../asset/b2.svg').default} alt='b1' />
          <img className={'c1'} src={require('../asset/b2.png')} alt='c1' />
          <img className={'c1-mobile'} src={require('../asset/b2-mobile.png')} alt='c1-mobile' />
        </div>
        <div className={'text'}>
          <h3 className={'title'}>
            Design
          </h3>
          <p>
            I chose a youthful design approach while preserving TechDesk's existing framework. This decision appeals to a younger demographic while maintaining alignment with our brand's identity and values. The result is a blend of tradition and innovation that resonates with both current and potential customers.
          </p>
        </div>
      </section>
      <section className={'description techdesk'}>
        <div className={'image'}>
          <img src={require('../asset/d2.svg').default} alt='d2' />
        </div>
      </section>
    </div>
  );
}

export default Techdesk;