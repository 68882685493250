import './Home.scss';

function Home(props) {
  return (
    <section id="home" className="Home" >
      <header>
        <h1>Hi! I’m Tamar</h1>
        <p>A graphic designer with a passion for making things look good. I have a 6 year experience in what I love to do the most. I'm also pretty funny.
          I am a creative and innovative thinker with a strong attention to detail.
          I bring a dynamic spirit and a strong
          aspiration to evolve alongside the workplace and the collaborative team.
          I am always looking for new ways to improve my skills and knowledge.</p>
        <button>
          <a href={'/cv.pdf'} rel={"noreferrer noopener nofollow"} target={'_blank'}>Download CV</a></button>
        <div className={'apps'}>
          <img src={'ps.png'} alt={'ps'} />
          <img src={'id.png'} alt={'ps'} />
          <img src={'ai.png'} alt={'ps'} />
          <img src={'xd.png'} alt={'ps'} />
          <img src={'br.png'} alt={'ps'} />
          <img src={'figma.png'} alt={'ps'} />
        </div>
      </header>
      {props.children}
    </section>
  );
}

export default Home;