import './Project.scss';
import Fibon from "./fibon/Fibon";
import {useNavigate, useParams} from "react-router-dom";
import Techdesk from "./techdesk/Techdesk";
import JourneyHub from "./journyhub/Journeyhub";

function Project() {
  const {project} = useParams();
  const navigate = useNavigate();

  const renderProject = () => {
    if (project === 'fibonacci') return <Fibon/>
    if (project === 'techdesk') return <Techdesk/>
    if (project === 'journeyhub') return <JourneyHub/>
    navigate('/');
    return null
  }
  return renderProject()
}

export default Project;