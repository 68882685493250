import './Footer.scss';
import {forwardRef} from "react";

function Footer(props, ref) {
  return (
    <footer className="Footer" ref={ref}>
      <ul>
        <li>
          <a href={'tel:+972543002921'} rel={'noopener nofollow noreferrer'}>
            <img src={'phone.png'} alt={'phone'} />
            <span>+972-54-3002921</span>
          </a>
        </li>
        <li className={'line'}></li>
        <li>
          <a href={'mailto:tamarb94@gmail.com'} rel={'noopener nofollow noreferrer'}>
            <img src={'mail.png'} alt={'mail'} />
            <span>tamarb94@gmail.com</span>
          </a>
        </li>
        <li className={'line'}></li>
        <li>
          <a href={'https://linkedin.com/in/tamar-dadon'} rel={'noopener nofollow noreferrer'} target={"_blank"}>
            <img src={'linkedin.png'} alt={'linkedin'} />
            <span>https://linkedin.com/in/tamar-dadon</span>
          </a>
        </li>
      </ul>
    </footer>
  );
}

export default forwardRef(Footer);